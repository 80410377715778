<template>
  <b-card>
    <b-card-body>

      <b-row>
        <!-- User Info: Left col -->
        <!-- User Avatar & Action Buttons -->
        <div class="mr-15">
          <h2 class="mb-1 text-primary text-uppercase">
            <b>{{ elementData.name }}</b>
          </h2>
        </div>
        <b-col
          cols="4"
          md="4"
          lg="4"
        >
          <!-- Media -->
          <b-media>
            <b-col
              cols="12"
              md="4"
              class="text-center mb-1"
            >
              <b-img
                :src="logo"
                text=""
                variant="light-success"
                fluid
                class="border"
              />
            </b-col>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="12"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.nit') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.nit }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.address') }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.address }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.department') }}</span>
              </th>
              <td class="pb-50">
                {{ getTypeValue(elementData, 'department') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(elementName+'.object.city') }}</span>
              </th>
              <td class="pb-50">
                {{ getTypeValue(elementData, 'city') }}
              </td>
            </tr>

          </table>

          <br><br>

          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('contact.section.contact_data') }}</b>
          </h3>
          <div>
            <b-table
              striped
              hover
              :items="elementData.contacts"
              :fields="contact_data_fields"
            />
          </div>

          <br><br>

          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('contact.section.contacts') }}</b>
          </h3>
          <div>
            <b-table
              striped
              hover
              :items="elementData.guardians"
              :fields="contact_fields"
            />
          </div>

        </b-col>
      </b-row>

      <br><br>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BTable, BMedia, BImg,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BCardHeader, BCardBody, BTable, BMedia, BImg,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
    elementLogo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logo: null,
      contact_data_fields: [
        {
          key: 'type.value',
          label: 'Tipo',
        },
        {
          key: 'data',
          label: 'Información',
        },
      ],
      contact_fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'job',
          label: 'Cargo',
        },
        {
          key: 'phone',
          label: 'Teléfono',
        },
        {
          key: 'email',
          label: 'Correo electrónico',
        },
      ],
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
  },
  methods: {
    router() {
      return router
    },
    handleLogoImage() {
      this.createLogoBase64mage(this.$refs.refInputLogo.files[0])
      this.logoUpdated = true
    },
    createLogoBase64mage(logo) {
      const reader = new FileReader()
      reader.onload = e => {
        this.logo = e.target.result
      }
      reader.readAsDataURL(logo)
    },
  },
  setup() {
    return {
      avatarText,
      getTypeValue(element, type) {
        return element[type] ? element[type].value : ''
      },
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status === 0) return this.$t(`${this.elementName}.status.inactive`)
        return this.$t(`${this.elementName}.status.active`)
      },
    }
  },
}
</script>

<style>

</style>
